
import { t } from '@lingui/macro'
import { authApi, monitorApi } from '../../api'
import { removeCookie, setCookie } from '../../helpers'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const monitorLogin = createAsyncThunk('auth/monitor', async (deviceId, thunkAPI) => {
    try {
        const token = await authApi.monitorLogin(deviceId)
        return { token, deviceId }
    } catch (err) {
        return thunkAPI.rejectWithValue(err)
    }
})

const getMonitor = createAsyncThunk('monitors', async (_, thinkApi) => {
    try {
        const data = await monitorApi.getMonitor()
        return data
    } catch (err) {
        const { status = "", data = "" } = err?.response || {};
        return thinkApi.rejectWithValue({ status, message: data })
    }
})

const createMonitor = createAsyncThunk('monitors/create', async (data, thinkApi) => {
    try {
        const result = await monitorApi.createMonitor(data)
        return result
    } catch (err) {
        return thinkApi.rejectWithValue()
    }
})

const updateMonitor = createAsyncThunk('monitors/update', async (data, thinkApi) => {
    try {
        const result = await monitorApi.updateMonitor(data)
        return result
    } catch (err) {
        return thinkApi.rejectWithValue()
    }
})

const deleteMonitor = createAsyncThunk('monitors/delete', async (data, thinkApi) => {
    try {
        const result = await monitorApi.deleteMonitor(data)
        return result
    } catch (err) {
        return thinkApi.rejectWithValue()
    }
})

const clearMonitor = createAsyncThunk('monitors/clear', async (data, thinkApi) => {
    try {
        const result = await monitorApi.clearMonitor(data)
        return result
    } catch (err) {
        return thinkApi.rejectWithValue()
    }
})

const disconnectMonitor = createAsyncThunk('monitors/disconnect', async (data, thinkApi) => {
    try {
        const result = await monitorApi.disconnectMonitor(data)
        return result
    } catch (err) {
        return thinkApi.rejectWithValue()
    }
})

const getAllMonitors = createAsyncThunk('monitors/all', async (_, thinkApi) => {
    try {
        const data = await monitorApi.getAllMonitors()
        return data
    } catch (err) {
        return thinkApi.rejectWithValue()
    }
})

const initialState = {
    images: [],
    monitors: [],
    patient: null,
    dayTime: 6,
    nightTime: 19,
    deviceId: '',
    error: "",
    newDeviceId: '',
}
const monitorSlice = createSlice({
    name: 'monitor',
    initialState: initialState,
    reducers: {
        clearDeviceId: state => {
            state.deviceId = ''
        },
        clearNewDeviceId: state => {
            state.newDeviceId = ''
        },
        clearMonitorState: state => {
            state = initialState
            return state
        }
    },
    extraReducers: builder => {
        builder.addCase(monitorLogin.fulfilled, (state, action) => {
            const { token, deviceId } = action.payload
            state.error = ''
            state.deviceId = deviceId
            if (!!action.payload) {
                setCookie('token', token)
                setCookie('role', 'monitor')
            }
        })
        builder.addCase(monitorLogin.rejected, (state, action) => {
            state.error = t`Unable to connect with this screen number`
        })
        builder.addCase(getMonitor.fulfilled, (state, action) => {
            const { monitor, images, qr, patient } = action.payload
            const { deviceId, dayTime, nightTime } = monitor
            state.deviceId = deviceId
            state.dayTime = dayTime
            state.nightTime = nightTime
            state.images = images
            state.qr = qr
            state.patient = patient
        })
        builder.addCase(getMonitor.rejected, (state, action) => {
            console.error("MonitorSlice.getMonitor(): error", action.payload)

            const { data = "", status = "" } = action.payload || {};
            if (status !== "401" && status !== "403") state.error = "Something went wrong";
            if (data === "Unauthorized" || data === "Forbidden" || data.search("Monitor not found") !== -1) {
                console.log("getMonitor.rejected remove token",{data})
                removeCookie('token')
                removeCookie('role')
            }

            return state
        })
        builder.addCase(getAllMonitors.fulfilled, (state, action) => {
            state.monitors = action.payload
        })
        builder.addCase(createMonitor.fulfilled, (state, action) => {
            const { deviceId } = action.payload
            state.newDeviceId = deviceId
        })
        builder.addCase(createMonitor.rejected, (state, action) => {

        })
        builder.addCase(deleteMonitor.fulfilled, (state, action) => {
            state.error = ""
            state.result = ""
        })
        builder.addCase(deleteMonitor.rejected, (state, action) => {
            state.error = ""
        })
        builder.addCase(clearMonitor.fulfilled, (state, action) => {
            state.error = ""
            state.result = ""
        })
        builder.addCase(clearMonitor.rejected, (state, action) => {
            state.error = ""
        })
        builder.addCase(disconnectMonitor.fulfilled, (state, action) => {
            state.error = ""
        })
        builder.addCase(disconnectMonitor.rejected, (state, action) => {
            state.error = ""
        })
    }
})

export const monitorActions = {
    getMonitor,
    monitorLogin,
    createMonitor,
    getAllMonitors,
    deleteMonitor,
    clearMonitor,
    updateMonitor,
    disconnectMonitor,
    ...monitorSlice.actions
}

export default monitorSlice.reducer