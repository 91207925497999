import { getCookie } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Wifi, WifiOff } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Typography, TextField, Box, Grid, MenuItem } from '@mui/material'
import { adminActions, monitorActions } from '../../redux/actions'
import { Dialog, GradientButton, ScreenLayout, Table, useNotification } from '../../components'
import {
    CleaningServicesOutlined as ClearIcon,
    DeleteOutlineOutlined as DeleteIcon,
    SensorsOffOutlined as DisconnectIcon,
    ModeEdit as EditIcon,
    Refresh as RefreshIcon
} from '@mui/icons-material';
import { BaseButton, LayoutButton } from '../../components/buttons'
import { Trans, t } from '@lingui/macro'
import { monitorApi } from '../../api'

const statuses = {
    'connected': {
        text: t`Connected`,
        color: '#219653',
        Icon: Wifi
    },
    'disconnected': {
        text: t`Disconnected`,
        color: '#F2994A',
        Icon: WifiOff
    }
}

export const AdminMonitors = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { showNotification } = useNotification()
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('')
    const [openDialog, setOpenDialog] = useState(false)
    const [dialogType, setDialogType] = useState('')
    const [currentMonitor, setCurrentMonitor] = useState('')
    const { email } = useSelector(state => state.admin)
    const { isMobile } = useSelector(state => state.base)
    const [newDescription, setNewDescription] = useState('')
    const [dayTime, setDayTime] = useState(`06`)
    const [nightTime, setNightTime] = useState(`19`)
    const { monitors, newDeviceId } = useSelector(state => state.monitor)
    const selectItems = [{ value: 'connected', text: t`Connected` }, { value: 'disconnected', text: t`Disconnected` }]
    const hours = Array.from({ length: 24 }, (_, i) => (i + 1).toString().padStart(2, '0'));

    const getAllMonitors = () => {
        dispatch(monitorActions.getAllMonitors())
    }

    useEffect(() => {
        if (!getCookie('token'))
            navigate('/admin')
        else if (email)
            getAllMonitors()
        else
            dispatch(adminActions.getAdmin())
    }, [email, dispatch, navigate, newDeviceId])

    const getStatusCell = status => {
        const { text, color, Icon } = statuses[status]
        return isMobile ? <Icon sx={{ color }} /> : <Typography color={color}>{text}</Typography>
    }

    const onEditMonitorSubmit = async () => {
        const result = await dispatch(monitorActions.updateMonitor({
            id: currentMonitor._id,
            description: newDescription,
            dayTime,
            nightTime
        }))
        if (result?.error) {
            showNotification(t`An error occurred while updating the screen`, "error")
            return
        }
        getAllMonitors()
        showNotification(t`The screen was updated successfully`, "success")
        setNewDescription('')
        handleDialogClose()
    }

    const onNewMonitorSubmit = async () => {
        if (newDeviceId) {
            dispatch(monitorActions.clearNewDeviceId())
            handleDialogClose()
        } else {
            const result = await dispatch(monitorActions.createMonitor({description: newDescription, dayTime, nightTime}))
            if (result?.error) {
                showNotification(t`An error occurred while adding the screen`, "error")
                return
            }
            showNotification(t`The screen was added successfully`, "success")
            setNewDescription('')
        }
    }

    const handleDialogClose = () => {
        setDialogType("")
        setOpenDialog(false)
        setCurrentMonitor("")
    }

    const handleConfirmDelete = async () => {
        const result = await dispatch(monitorActions.deleteMonitor({
            id: currentMonitor._id,
            connectionId: currentMonitor.connectionId
        }))
        if (result?.error) {
            showNotification(t`An error occurred while deleting the screen`, "error")
            return
        }
        showNotification(t`The screen was deleted`, "success")
        getAllMonitors()
        handleDialogClose()
    }

    const handleConfirmClear = async () => {
        const result = await dispatch(monitorActions.clearMonitor({
            id: currentMonitor._id,
            connectionId: currentMonitor.connectionId
        }))
        if (result?.error) {
            showNotification(t`An error occurred while deleting the data`, "error")
            return
        }
        showNotification(t`All screen-related data was successfully deleted`, "success")
        getAllMonitors()
        handleDialogClose()
    }

    const handleConfirmDisconnect = async () => {
        const { deviceId = '' } = currentMonitor || {}
        const result = await dispatch(monitorActions.disconnectMonitor({ deviceId }))
        if (result?.error) {
            showNotification(t`An error occurred while disconnecting the screen`, "error")
            return
        }
        showNotification(t`The screen was successfully disconnected`, "success")
        getAllMonitors()
        handleDialogClose()
    }

    const handleConfirmReload = async () => {
        const { deviceId = '' } = currentMonitor || {}
        const result = await monitorApi.reloadMonitor({ deviceId })
        const text = deviceId === "All" ? t`All monitor got reloaded` : result === "reloaded" ? t`Monitor ${deviceId} is reloaded` : t`Monitor ${deviceId} not connected`
        showNotification(text, result === "reloaded" ? "success" : "warning")
        handleDialogClose()
    }

    const dataStructure = [
        { header: t`Screen Number`, key: 'deviceId' },
        { header: t`Status`, key: 'status', func: getStatusCell },
        { header: t`Description`, key: 'description' }
    ]


    const actions = [
        {
            text: t`Edit`,
            Icon: EditIcon,
            onClick: (item) => {
                setCurrentMonitor(item)
                setDayTime(item.dayTime || 6)
                setNightTime(item.nightTime || 19)
                setNewDescription(item.description)
                setDialogType("edit")
            }
        },
        {
            text: t`Disconnect`,
            Icon: DisconnectIcon,
            onClick: (item) => {
                setCurrentMonitor(item)
                setDialogType("disconnect")
            }
        },
        {
            text: t`Clear`,
            Icon: ClearIcon,
            onClick: (item) => {
                setCurrentMonitor(item)
                setDialogType("clear")
            }
        },
        {
            text: t`Delete`,
            Icon: DeleteIcon,
            onClick: (item) => {
                setCurrentMonitor(item)
                setDialogType("delete")
            }
        },
        {
            text: t`Reload`,
            Icon: RefreshIcon,
            onClick: (item) => {
                setCurrentMonitor(item)
                setDialogType("reload")
            }
        }
    ]

    const renderScreenForm = () => {
        let isEdit = !!currentMonitor
        return (
            <>
                <Typography>
                    {
                        newDeviceId ?
                            t`Screen Number for Screen System Connection` :
                            isEdit ? t`Edit Screen Description` :
                                t`Please add a description for the new screen`
                    }
                </Typography>
                <TextField
                    disabled={!!newDeviceId}
                    placeholder={t`Room 1, Bed 1`}
                    value={newDeviceId || newDescription}
                    onChange={event => setNewDescription(event.target.value)}
                    sx={{
                        flexGrow: 1,
                        marginBlock: '1rem',
                        '&& .Mui-disabled': {
                            fontSize: '2em',
                            textAlign: 'center',
                            WebkitTextFillColor: '#000'
                        }
                    }}
                />
                <Grid container direction={"row"} spacing={2}>
                    <Grid item sm={6}>
                        <Typography>Light mode time</Typography>
                        <TextField
                            select
                            fullWidth
                            placeholder={t`6:00`}
                            value={dayTime}
                            onChange={event => {setDayTime(event.target.value); console.log(event.target.value)}}
                            SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200
                                    },
                                  },
                                },
                              }}
                            sx={{
                                flexGrow: 1,
                                marginBlock: '1rem',
                            }}
                        >
                            {hours.map(hour => (
                                <MenuItem key={hour} value={hour}>{hour}:00</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography>Dark mode time</Typography>
                        <TextField
                            select
                            fullWidth
                            placeholder={t`19:00`}
                            value={nightTime}
                            onChange={event => setNightTime(event.target.value)}
                            SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200
                                    },
                                  },
                                },
                              }}
                            sx={{
                                flexGrow: 1,
                                marginBlock: '1rem',
                            }}
                            >
                            {hours.map(hour => (
                                <MenuItem key={hour} value={hour}>{hour}:00</MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                </Grid>

                <GradientButton
                    onClick={isEdit ? onEditMonitorSubmit : onNewMonitorSubmit}
                    variant='contained'>
                    {newDeviceId ? t`Close` : t`Submit`}
                </GradientButton>
            </>
        )
    }

    const renderConfirmDelete = () => {
        const { deviceId = '' } = currentMonitor || {}
        return (
            <>
                <Typography>{t`Are you sure you want to delete the screen ${deviceId}? This will delete all information linked to the screen`}</Typography>
                <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                    <BaseButton
                        variant="contained"
                        color="error"
                        onClick={handleDialogClose}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Cancel`}
                    </BaseButton>
                    <GradientButton
                        variant="contained"
                        onClick={handleConfirmDelete}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Delete`}
                    </GradientButton>
                </Box>
            </>
        )
    }

    const renderConfirmClear = () => {
        const { deviceId = '' } = currentMonitor || {}
        return (
            <>
                <Typography>{t`Are you sure you want to clear all data linked to screen ${deviceId}?`}</Typography>
                <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                    <BaseButton
                        variant="contained"
                        color="error"
                        onClick={handleDialogClose}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Cancel`}
                    </BaseButton>
                    <GradientButton
                        variant="contained"
                        onClick={handleConfirmClear}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Clear`}
                    </GradientButton>
                </Box>
            </>
        )
    }

    const renderConfirmDisconnect = () => {
        const { deviceId = '' } = currentMonitor || {}
        return (
            <>
                <Typography>{t`Are you sure you want to disconnect the screen ${deviceId}?`}</Typography>
                <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                    <BaseButton
                        variant="contained"
                        color="error"
                        onClick={handleDialogClose}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Cancel`}
                    </BaseButton>
                    <GradientButton
                        variant="contained"
                        onClick={handleConfirmDisconnect}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Disconnect`}
                    </GradientButton>
                </Box>
            </>
        )
    }

    const renderConfirmReload = () => {
        const { deviceId = '' } = currentMonitor || {}
        const text = deviceId === "All" ? t`Are you sure you want to reload all the screens?` : t`Are you sure you want to reload the screen ${deviceId}?`
        return (
            <>
                <Typography>{text}</Typography>
                <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
                    <BaseButton
                        variant="contained"
                        color="error"
                        onClick={handleDialogClose}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Cancel`}
                    </BaseButton>
                    <GradientButton
                        variant="contained"
                        onClick={handleConfirmReload}
                        style={{ marginInlineEnd: 5 }}
                    >
                        {t`Reload`}
                    </GradientButton>
                </Box>
            </>
        )
    }

    const dialogContent = {
        add: renderScreenForm(),
        edit: renderScreenForm(),
        delete: renderConfirmDelete(),
        clear: renderConfirmClear(),
        disconnect: renderConfirmDisconnect(),
        reload: renderConfirmReload(),
    }

    const dialogTitle = {
        add: t`Add New Screen`,
        edit: t`Edit Screen`,
        delete: t`Delete Screen`,
        clear: t`Clear Screen Data`,
        disconnect: t`Disconnect Screen`

    }

    const renderReloadButton = () => {
        const currentWidth = isMobile ? "calc(100% - 7px)" : "30rem";
        if (!isMobile) {
            return (
                <LayoutButton
                    text={<Trans>Reload All Monitors</Trans>}
                    onclick={() => {
                        setCurrentMonitor({ deviceId: "All" })
                        setDialogType("reload")
                    }}
                />
            )
        }
        return (
            <Grid display={"flex"} flexDirection={"column"} alignItems={isMobile ? "" : "center"}>
                <GradientButton
                    size="large"
                    variant="contained"
                    sx={[{ textTransform: "capitalize", marginTop: '1rem', marginInline: '.6rem' }, !isMobile && { maxWidth: currentWidth }]}
                    onClick={() => { setOpenDialog(true); setCurrentMonitor(null); setDialogType("add") }}
                    fullWidth={!isMobile && true}
                >
                    <Trans>Add New Screen</Trans>
                </GradientButton>
                <GradientButton
                    size="large"
                    variant="contained"
                    fullWidth={!isMobile && true}
                    sx={[{ textTransform: "capitalize", marginTop: '1rem', marginInline: '.6rem' }, !isMobile && { maxWidth: currentWidth }]}
                    onClick={() => {
                        setCurrentMonitor({ deviceId: "All" })
                        setDialogType("reload")
                    }}>
                    <Trans>Reload All Monitors</Trans>
                </GradientButton>
            </Grid>

        )
    }

    return (
        <Container maxWidth="xl" sx={{ marginTop: isMobile ? '1rem' : '2rem', display: 'flex', flex: 1, flexGrow: 1, flexDirection: 'column' }} >
            <Typography variant={isMobile ? 'h4' : 'h3'}>{t`Screen Management`}</Typography>
            <ScreenLayout
                search={search}
                select={status}
                isMobile={isMobile}
                selectItems={selectItems}
                selectPlaceholder={t`Status`}
                buttonText={t`Add New Screen`}
                onButtonClick={() => { setOpenDialog(true); setCurrentMonitor(null); setNewDescription(""); setDialogType("add") }}
                renderButton={renderReloadButton}
                onSearchChange={value => setSearch(value)}
                onStatusChange={value => setStatus(value)}>
                <Table
                    data={monitors}
                    search={search}
                    actions={actions}
                    isMobile={isMobile}
                    strictSearch={status}
                    dataStructure={dataStructure}
                />
            </ScreenLayout>
            <Dialog
                title={dialogType === 'add' && !!newDeviceId ? t`The screen was added successfully` : dialogTitle[dialogType]}
                open={openDialog || !!dialogType}
                onClose={handleDialogClose}>
                {dialogContent[dialogType]}
            </Dialog>
        </Container>
    )
} 