import React, { useEffect, useRef, useState } from 'react'
import { calculateMillisecondsUntil, getCookie, removeCookie, setCookie } from '../../helpers'
import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { monitorActions } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { SlideShow, SmallLogo } from '../../components'
import { io } from 'socket.io-client'
import { languageInterval, serverUrl } from '../../config'
import FilterHdrIcon from '@mui/icons-material/FilterHdr';
import { Trans, t } from '@lingui/macro'
import { LANGUAGES } from '../../enums'
import { useLanguage } from '../../components/LanguageContext'

export const MonitorSlideShow = () => {
  const [socket, setSocket] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { changeLanguage } = useLanguage()
  const intervalRef = useRef(null)

  const languages = Object.keys(LANGUAGES);
  const [languageIndex, setLanguageIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const { deviceId, images = [], qr, patient, dayTime = "06", nightTime = "19", error } = useSelector(state => state.monitor)
  const { name = '', birthCountry = '', family = '', profession = '', hobbies = '', gossip = '', landmarks = '' } = patient || {}

  const profile = [
    {
      label: t`I was born in`,
      value: birthCountry
    },
    {
      label: t`My family and roots are`,
      value: family
    },
    {
      label: t`I work in`,
      value: profession
    },
    {
      label: t`My hobbies are`,
      value: hobbies
    },
    {
      label: t`Something interesting about me is `,
      value: gossip
    },
    {
      label: t`I achieved in my life`,
      value: landmarks,
      labelCol: 12,
      valueCol: 12,
    },
  ]

  useEffect(() => {
    if (!deviceId) return;

    const socketIO = io(serverUrl, {
      auth: {
        token: getCookie("token"),
        reconnection: true, // Whether to automatically attempt to reconnect
        reconnectionAttempts: 5000, // Number of reconnection attempts before giving up
        reconnectionDelay: 10000, // How long to initially wait before attempting a new reconnection
        reconnectionDelayMax: 15000,
      }
    })

    setSocket(socketIO)
  }, [deviceId])


  const scheduleThemeToggle = (hour, minute, callback) => {
    const timeUntilNextTrigger = calculateMillisecondsUntil(hour, minute);
    const timeoutId = setTimeout(() => {
      callback();
      scheduleThemeToggle(hour, minute, callback);
    }, timeUntilNextTrigger);
    return timeoutId;
  };

  useEffect(() => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const dayVal = parseInt(dayTime)
    const nightVal = parseInt(nightTime)

    if (hours >= dayVal && hours < nightVal) {
      setIsDarkMode(false);
    } else {
      setIsDarkMode(true);
    }
    const nightTimeout = scheduleThemeToggle(nightVal, 0, () => setIsDarkMode(true));
    const dayTimeout = scheduleThemeToggle(dayVal, 4, () => setIsDarkMode(false));

    return () => {
      clearTimeout(nightTimeout);
      clearTimeout(dayTimeout);
    };
  }, [dayTime, nightTime]);

  useEffect(() => {
    setIsVisible(true);
  }, [languageIndex]);

  const languageTimeout = () => setTimeout(() => {
    const newIndex = (languageIndex + 1) % languages.length;
    const newLanguage = languages[newIndex];
    changeLanguage(newLanguage)
    setLanguageIndex(newIndex);
  }, 700)

  useEffect(() => {
    if (images.length > 1) return;

    const languageIntervalFunc = setInterval(() => {
      setIsVisible(false)
      languageTimeout()
    }, languageInterval);
    
    return () => {
      clearInterval(languageIntervalFunc)
      clearTimeout(languageTimeout)
    };
  }, [languageIndex]);

  useEffect(() => {
    if (imageIndex !== 0) return;
    setIsVisible(false)
    languageTimeout()

    return () => {
      clearTimeout(languageTimeout)
    }
  }, [imageIndex])

  const onSocketDisconnect = () => {
    removeCookie("token")
    navigate("/monitor")
  }

  const onConnected = () => {
    console.log("connected")
    if (!deviceId) return
    socket.emit("connected", deviceId)

    if (!!intervalRef.current)
      clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      if (socket.connected) {
        socket.emit('ping', deviceId);
      } else {
        console.log('Attempting to reconnect...');
        socket.connect();
      }
    }, 20000)
  }

  const onDisconnect = (msg) => {
    // socket.connect()
  }

  const monitorUpdate = async (msg) => {
    if (msg?.event === "deleted") {
      console.log("monitor deleted, disconnecting", msg)
      socket.disconnect()
      onSocketDisconnect()
      return
    } else if (msg?.event === "cleared") {
      setCookie("token", msg?.data?.token)
    }

    await dispatch(monitorActions.getMonitor())
  }

  const onReload = () => {
    window.location.reload()
  }

  const connect = () => {
    socket.on("connect", onConnected)
    socket.on("monitorUpdate", monitorUpdate)
    socket.on("disconnect", onDisconnect)
    socket.on("reloadMonitor", onReload)
    socket.on("disconnectMonitor", () => {
      socket.disconnect()
      onSocketDisconnect()
    })
  }

  useEffect(() => {

    if (!getCookie('token')) navigate('/monitor')

    return () => {
      dispatch(monitorActions.clearMonitorState())
      if (!!intervalRef.current)
        clearInterval(intervalRef.current)
      if (!socket) return;
      socket.disconnect()
      socket.removeAllListeners()
    }
  }, [])

  useEffect(() => {
    console.log({ error })
    if (error) navigate('/monitor')
  }, [error])

  useEffect(() => {
    if (!getCookie('token') || error) navigate('/monitor')
    if (!deviceId) dispatch(monitorActions.getMonitor())
    if (deviceId && socket) connect()
  }, [deviceId, socket, dispatch, navigate])


  useEffect(() => {
    if (deviceId && !images.length) dispatch(monitorActions.getMonitor())
  }, [deviceId])

  const renderPatientInfo = () => {
    const greetingFontSize = '1.8em';
    if (!patient || (!name && !birthCountry && !family && !profession && !hobbies && !gossip && !landmarks)) {
      return (
        <Grid container display='flex' justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
          <Grid item sm={6}>
            <Typography variant='h4' textAlign={"center"}><Trans>Meet the "Empathy" system</Trans></Typography>
            <Typography variant='h4' textAlign={"center"}><Trans>A system built for you and for us, the caregiving team</Trans></Typography>
          </Grid>
          <Grid item sm={10}>
            <Grid container mt={2} justifyContent={"center"}>
              <Grid item xs={4}>
                <Box {...(isDarkMode? { marginTop: 5 }: {marginInlineEnd: -5})} >
                  <CardMedia
                    component="img"
                    height={500}
                    style={{ objectFit: "contain", ...(isDarkMode ? { filter: "brightness(0.8)" } : {}) }}
                    image={qr}
                    alt="qr code"
                  />
                  <Typography fontSize='1.5em' marginInlineStart={isDarkMode? 1: 8} maxWidth={"85%"}>{t`Due to constraints, the initial version of the system is accessible in Hebrew only. We hope that in the near future we will be able to provide a multi-language version`}</Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box marginTop={4} marginInlineStart={5}>
                  <Typography fontSize={greetingFontSize}>{t`Dear patient, dear family`}</Typography>
                  <Typography fontSize={greetingFontSize} mt={2}>{t`Due to the reality in the hospital, we naturally tend to see patients as just "patients" and sometimes forget that everyone has better and worse moments. The system will help us see the person behind the pajamas, in a different light and from a different perspective;`}</Typography>
                  <Typography fontSize={greetingFontSize} mt={2}>{t`When he or she is elsewhere, in a different situation, wearing the clothes he or she likes, stronger and in his or her comfort zone.`}</Typography>
                  <Typography fontSize={greetingFontSize}>{t`With family, at work, traveling, engaged in his or her hobbies, receiving an award, appointed, establishing, creating...`}</Typography>
                  <Typography fontSize={greetingFontSize} mt={2}>{t`We would be happy if you could give us the opportunity for a different perspective...`}</Typography>
                  <Typography fontSize={greetingFontSize}>{t`Thank you.`}</Typography>
                  <Typography fontSize={greetingFontSize} mt={2}>{t`P.S.`}</Typography>
                  <Typography fontSize={greetingFontSize}>
                    {t`All you need is to scan the QR code, and after a short process, the materials you choose will be displayed on this screen.`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return renderSlideShow()
  }

  const renderSlideShow = () => {
    return (
      <Grid container flexDirection={"row"} mt={2} paddingInlineEnd={1}>
        <Grid item xl={3} md={4} xs={4}>
          {name && <>
            <Typography fontSize={'3em'} whiteSpace={"normal"} textAlign={"center"}>{t`Hello, I am ${name}`}</Typography>
            <Typography fontSize={'2em'} whiteSpace={"normal"} textAlign={"center"}>{t`Nice to meet you and thank you for the opportunity to present a taste of my life to you`}</Typography>
          </>
          }
          <Box mt={2}>
            {profile.map(({ value, label }) => {
              if (!value) return null
              return (
                <Typography key={label} fontSize={25} fontWeight={"600"} marginInlineEnd={2}>
                  {`${label} `}<Box display="inline" fontWeight={400}>{value}</Box>
                </Typography>
              )
            })}
          </Box>
        </Grid>
        <Grid item xl={9} md={8} xs={8}>
          {images.length ? <SlideShow images={[...images]} isDarkMode={isDarkMode} index={imageIndex} setIndex={setImageIndex} /> : (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
              <Box style={{ width: "600px", height: "500px", border: "15px solid gray", borderRadius: 20, backgroundColor: isDarkMode ? "#ccc" : "floralWhite" }}>
                <FilterHdrIcon style={{ width: "100%", height: "100%", color: isDarkMode ? "gray" : "darkcyan" }} />
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    )
  }

  const containerDarkStyle = { maxHeight: "100vh", backgroundImage: "linear-gradient(#141414, #2b2b2b)", color: "white" }
  const qrDarkStyle = { filter: "brightness(0.8)", marginTop: 9 }

  return (
    <Container style={{ maxWidth: "100vw", height: "100vh", ...(isDarkMode ? containerDarkStyle : {}) }}>
      <Box className={`screen ${isVisible ? "visible" : ""}`}>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} paddingTop={2}>
          <SmallLogo style={{ width: "unset" }} />
          <Box display={"flex"} flexDirection={"row"}>
            <Typography variant="h4" paddingInlineEnd={1}>{t`Screen number: ${deviceId}`}</Typography>
            <CardMedia
              component="img"
              image={qr}
              style={{ 
                height: 150, 
                width: 150, 
                aspectRatio: 1, 
                marginTop: -5, 
                ...(isDarkMode ? qrDarkStyle : {}) }}
              alt="qr code"
            />
          </Box>
        </Box>
        {images.length ? renderSlideShow() : renderPatientInfo()}
      </Box>
    </Container>
  )
}