import Carousel from 'react-bootstrap/Carousel';
import { ImageStream } from './ImageStream';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Box, Typography } from '@mui/material';
import { slideshowSpeedRate } from '../config';

export const SlideShow = ({ images, isDarkMode = false, index = 0, setIndex = () => null }) => {
  const interval = images?.length ? slideshowSpeedRate : null

  return (
    <Carousel interval={interval} pause={false} indicators={false} controls={false} onSlide={setIndex}>
      {images.filter(item => !!item).map((item, index) => {
        if (!item) return <></>
        const { description, title } = item?.metadata || {}
        return (
          <Carousel.Item key={index} style={{ height: "calc(100vh - 200px)" }}>
            <Box style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
              <Typography fontSize={48} textAlign={"center"}>{title}</Typography>
              <ImageStream id={item._id} styles={isDarkMode ? {filter: "brightness(0.7)"} : {}}/>
              <Typography fontSize={30} whiteSpace={"normal"} lineHeight={1.2} marginTop={1}>{description}</Typography>
            </Box>
          </Carousel.Item>
        )
      })}
    </Carousel>
  );
}
